<script>
import { defineComponent } from 'vue';
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import { mapGetters } from 'vuex';
import DeleteDialog from '@/components/DeleteDialog.vue';
import FileHandlerService from '@/services/file-handler';
import { DHME_MANUAL_IMPORT } from '@/modules/modules';
import { ANT_DELIMITER } from '@/services/uuid-helper';
import { moduleHeaders } from '@/modules/daiwa-house-modular-europe/ManualImport/columns';
import {
  queryTasksAdvanced,
  queryTasksV2,
  removeAppendixV2,
  uploadAppendixV2,
} from '@/services/api/v2/tasks.v2.api';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import AntInput from '@/components/AntInput.vue';
import {
  deleteRecordV2,
  importRecordsV2,
} from '@/services/api/v2/records.v2.api';
import { bookOfObjects } from '@/services/bookOf';
import qs from 'qs';

export default defineComponent({
  name: 'DhmeManualImportModules',
  components: { AntInput, DeleteDialog, DynamicDataTable },
  data: () => {
    return {
      newBuildNr: null,
      moduleToDelete: null,
      manualImportModuleHeaders: moduleHeaders,
      isLoading: false,
      selectedCell: null,
      cellValue: null,
      csvImportFile: undefined,
      deleteMode: false,
      currentCellValue: null,
      ctrlAltDown: false,
      moduleAssemblyTasks: [],
      importMenu: false,
      importLoading: false,
      ignoreModuleTypes: false,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      modules: 'dhmeManualImportStore/modules',
      modulesTableId: 'dhmeManualImportStore/modulesTable',
      placementPhases: 'dhmeManualImportStore/placementPhases',
    }),
  },
  async mounted() {
    await this.fetchData();
    window.addEventListener('keydown', this.setEventListeners);
    window.addEventListener('keyup', this.setEventListeners);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.setEventListeners);
    window.removeEventListener('keyup', this.setEventListeners);
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('dhmeManualImportStore/fetchModules');
      await this.$store.dispatch('dhmeManualImportStore/fetchPlacementPhases');
    },
    async previewDocument(document) {
      let blob = await FileHandlerService.fetchRecordDocument(
        document.id,
        this.project.id,
        this.modulesTableId
      );

      if (blob.type === 'application/pdf' || blob.type.startsWith('image/')) {
        this.$refs['table'].setDocumentPreview(URL.createObjectURL(blob));
      } else {
        FileHandlerService.downloadRecordDocument(
          this.$route.query.document,
          this.selectedProjectTable.project,
          this.selectedProjectTable.id
        );
      }
    },
    ANT_DELIMITER() {
      return ANT_DELIMITER;
    },
    async setEventListeners(event) {
      if (
        event.ctrlKey &&
        event.altKey &&
        event.code === 'KeyD' &&
        event.type === 'keydown'
      ) {
        this.deleteMode = !this.deleteMode;
        this.selectedCell = null;
      }

      if (this.selectedCell && event.type === 'keydown') {
        if (event.altKey && event.ctrlKey) {
          this.ctrlAltDown = true;
          let split = this.selectedCell.split(ANT_DELIMITER);
          let columnIndex = this.manualImportModuleHeaders.findIndex(
            (column) => column.value === split[1]
          );
          let recordIndex = this.modules.findIndex(
            (record) => record.id === split[0]
          );
          switch (event.key) {
            case 'ArrowLeft':
              if (this.cellValue !== this.currentCellValue) {
                await this.updateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              columnIndex = columnIndex - 1;
              this.cellValue = this.getCellValue(
                this.modules[recordIndex].id,
                this.manualImportModuleHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;
            case 'ArrowRight':
              if (this.cellValue !== this.currentCellValue) {
                await this.updateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              columnIndex = columnIndex + 1;
              this.cellValue = this.getCellValue(
                this.modules[recordIndex].id,
                this.manualImportModuleHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;
            case 'ArrowUp':
              if (this.cellValue !== this.currentCellValue) {
                await this.updateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              recordIndex = recordIndex - 1;
              this.cellValue = this.getCellValue(
                this.modules[recordIndex].id,
                this.manualImportModuleHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;
            case 'ArrowDown':
              if (this.cellValue !== this.currentCellValue) {
                await this.updateRecord(
                  split[0],
                  split[1],
                  this.currentCellValue
                );
              }
              recordIndex = recordIndex + 1;
              this.cellValue = this.getCellValue(
                this.modules[recordIndex].id,
                this.manualImportModuleHeaders[columnIndex].value
              );
              this.currentCellValue = this.cellValue;
              break;

            case 'Delete':
              await this.updateRecord(
                this.modules[recordIndex].id,
                this.manualImportModuleHeaders[columnIndex].value,
                null
              );
              this.currentCellValue = null;
              return;
          }
          if (
            columnIndex >= 0 &&
            columnIndex <= this.manualImportModuleHeaders.length &&
            recordIndex >= 0 &&
            recordIndex < this.modules.length
          ) {
            this.selectedCell = `${this.modules[recordIndex].id}${ANT_DELIMITER}${this.manualImportModuleHeaders[columnIndex].value}`;
          }
        } else {
          this.ctrlAltDown = false;
        }
      } else {
        this.ctrlAltDown = false;
      }
    },

    async updateAndRowDown() {
      let split = this.selectedCell.split(ANT_DELIMITER);
      let columnIndex = this.manualImportModuleHeaders.findIndex(
        (column) => column.value === split[1]
      );
      let recordIndex = this.modules.findIndex(
        (record) => record.id === split[0]
      );

      recordIndex = recordIndex + 1;

      if (
        columnIndex >= 0 &&
        columnIndex <= this.manualImportModuleHeaders.length &&
        recordIndex >= 0 &&
        recordIndex < this.modules.length
      ) {
        this.selectedCell = `${this.modules[recordIndex].id}${ANT_DELIMITER}${this.manualImportModuleHeaders[columnIndex].value}`;
        this.cellValue = this.getCellValue(
          this.modules[recordIndex].id,
          this.manualImportModuleHeaders[columnIndex].value
        );
        this.currentCellValue = this.cellValue;
      }
    },
    async importCsvData() {
      if (this.csvImportFile.name.split('.').pop() !== 'csv') {
        this.$store.commit('showNotification', {
          content: 'Only CSV files are accepted',
          color: 'error',
        });
        this.csvImportFile = undefined;
      } else {
        let body = {};

        await FileHandlerService.handleFile(this.csvImportFile).then(
          (value) => {
            body.records = value.data;
          }
        );

        this.$store
          .dispatch('importRecords', {
            tableId: this.modulesTableId,
            data: body,
          })
          .then(() => {
            this.csvImportFile = undefined;

            this.$store.dispatch('dhmeManualImportStore/fetchModules');
          });
      }
    },
    async createRecord() {
      this.isLoading = true;
      let body = {
        build_nr: this.newBuildNr,
      };

      await this.$store.dispatch(
        'dhmeManualImportStore/manualImportCreateRecord',
        body
      );

      this.newBuildNr = null;
      this.isLoading = false;
    },
    async uploadFile(recordId, column, value) {
      if (value) {
        let file = await FileHandlerService.handleFile(value);
        await this.updateRecord(recordId, column, file);
      }
    },
    async updateRecord(recordId, column, value) {
      this.isLoading = true;
      let body = {};
      body[column] = value;

      try {
        let record = this.modules.find((item) => item.id === recordId);
        record[column] = value;

        await this.$store.dispatch(
          'dhmeManualImportStore/manualImportUpdateRecord',
          {
            recordId: recordId,
            recordBody: body,
          }
        );
        this.isLoading = false;
        if (column === '2d_drawing') {
          await this.upload2DDrawing(value, recordId);
        }
      } catch (e) {
        console.log(e);
        this.$store.commit('showNotification', {
          content: 'something went wrong',
          color: 'error',
        });
        this.resetCell(recordId, column);
      }
    },
    async upload2DDrawing(file, recordId) {
      const record = this.modules.find((r) => r.id === recordId);

      const query = qs.stringify(
        {
          filters: {
            type: {
              type: {
                $eq: 'dhme-module-assembly',
              },
            },
            project: {
              $eq: this.project.id,
            },
            sbscode: {
              $eq: record.build_nr,
            },
          },
          fields: {
            tasks: ['id', 'title'].join(','),
            attachments: ['task', 'name', 'id'].join(','),
          },
          include: 'attachments',
          sort: ['title'],
        },
        {
          encodeValuesOnly: true,
        }
      );

      let { data: tasks } = await queryTasksAdvanced(
        this.project.license,
        query
      );

      if (tasks.length === 1) {
        let assemblyTask = tasks[0];
        let drawing = assemblyTask?.attachments.find(
          (a) => a.name === `2D_${record.module_id}`
        );
        if (drawing) {
          await removeAppendixV2(assemblyTask.id, drawing.id);
        }
        await uploadAppendixV2(assemblyTask.id, {
          name: `2D_${record.module_id}`,
          extension: file.extension,
          data: file.data,
        });
      }
    },
    setupDelete(module) {
      this.moduleToDelete = module;
    },
    async deleteModule() {
      this.isLoading = true;
      await this.$store.dispatch(
        'dhmeManualImportStore/manualImportDeleteRecord',
        this.moduleToDelete.id
      );
      this.moduleToDelete = null;
      this.isLoading = false;
    },
    getCellValue(id, column) {
      let record = this.modules.find((item) => item.id === id);
      return record[column];
    },
    selectCell(id, header, value) {
      if (this.deleteMode) {
        this.updateRecord(id, header, null);
      } else {
        this.selectedCell = `${id}${ANT_DELIMITER}${header}`;
        this.cellValue = value;
        this.currentCellValue = value;
      }
    },
    resetCell(id, column) {
      let record = this.modules.find((item) => item.id === id);
      if (record) {
        record[column] = this.cellValue;
      }
      this.selectedCell = null;
      this.cellValue = null;
      this.currentCellValue = null;
    },
    async importFromLayout() {
      this.importLoading = true;
      let { buildings } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_PROJECT_INTAKE_DATA',
            project: this.project.master_id,
            as: 'metadata',
            columns: [
              {
                name: 'project_id',
                conditions: [
                  {
                    operator: '=',
                    value: this.project.id,
                  },
                ],
              },
              {
                name: 'firm',
              },
            ],
          },
          {
            name: 'CFFA_DHME_PROJECT_INTAKE_BUILDINGS',
            project: this.project.master_id,
            as: 'buildings',
            columns: [
              {
                name: 'project_id',
                conditions: [
                  {
                    operator: '=',
                    value: this.project.id,
                  },
                ],
              },
              {
                name: 'ordinal',
              },
            ],
          },
        ],
      });

      let { layers } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_PROJECT_INTAKE_BUILDING_LAYERS',
            project: this.project.master_id,
            as: 'layers',
            columns: [
              {
                name: 'project_id',
                conditions: [
                  {
                    operator: '=',
                    value: this.project.id,
                  },
                ],
              },
              {
                name: 'building_id',
                conditions: [
                  {
                    operator: '=',
                    values: buildings.records.map((x) => x.id),
                  },
                ],
              },
              {
                name: 'layer',
              },
              {
                name: 'module_count',
              },
            ],
          },
        ],
      });

      let modules = [];
      let totalCount = layers.records.reduce(
        (total, l) => total + l.module_count,
        0
      );
      let totalIndex = 1;
      buildings.records.forEach((b) => {
        const buildingLayers = layers.records.filter(
          (l) => l.building_id === b.id
        );
        buildingLayers.forEach((l) => {
          for (let i = 1; i <= l.module_count; i++) {
            let obj = { id: null };
            let padded_i = String(i).padStart(
              Math.max(String(totalCount).length, 2),
              '0'
            );
            obj.build_nr = `${this.getColumnLabel(b.ordinal)}${l.layer}.${padded_i}`;
            obj.id =
              this.modules.find((m) => m.build_nr === obj.build_nr)?.id ?? null;
            modules.push(obj);
            totalIndex++;
          }
        });
      });

      const newBuildNumbers = new Set(modules.map((mod) => mod.build_nr));

      const buildNrsToDelete = this.modules
        .filter((mod) => !newBuildNumbers.has(mod.build_nr))
        .map((mod) => mod.id);

      if (buildNrsToDelete.length > 0) {
        await deleteRecordV2(this.modulesTableId, ...buildNrsToDelete);
      }

      let book = bookOfObjects('records', modules);
      let csv = book.convert('csv', 'string');
      let parsedCsv = btoa(unescape(encodeURIComponent(csv)));

      await importRecordsV2(this.modulesTableId, {
        records: parsedCsv,
      });
      await this.$store.dispatch('dhmeManualImportStore/fetchModules');
      this.importMenu = false;
      this.importLoading = false;
    },
    getColumnLabel(index) {
      let columnName = '';
      let dividend = index;
      let modulo;

      while (dividend > 0) {
        modulo = (dividend - 1) % 26;
        columnName = String.fromCharCode(65 + modulo) + columnName;
        dividend = parseInt((dividend - modulo) / 26);
      }

      return columnName;
    },
  },
});
</script>

<template>
  <dynamic-data-table
    ref="table"
    table-title="Modules"
    :table-records="modules"
    :table-headers="manualImportModuleHeaders"
    class="flex-grow-1 ant-glass-background radius-0"
    :is-loading="isLoading"
    :items-per-page="-1"
    :table-id="modulesTableId"
    :project-id="project.id"
    export-file-name="manual_import_module_export"
    has-options
    can-delete
    @deleteItem="setupDelete"
  >
    <template #table-buttons>
      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-icon
            :color="deleteMode ? 'primary' : ''"
            v-bind="attrs"
            v-on="on"
            @click="deleteMode = !deleteMode"
          >
            {{ deleteMode ? 'mdi-delete-empty' : 'mdi-delete-empty-outline' }}
          </v-icon>
        </template>
        <span>Toggle delete mode (Ctrl+Alt+D)</span>
      </v-tooltip>

      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2"
            :color="ctrlAltDown ? 'primary' : ''"
            v-bind="attrs"
            v-on="on"
          >
            mdi-arrow-all
          </v-icon>
        </template>
        <span
          >You can use Ctrl+Alt+Arrow keys to move from cell to cell when a cell
          is selected</span
        >
      </v-tooltip>
    </template>
    <template #table-options-menu>
      <v-menu v-model="importMenu" :close-on-content-click="false" left>
        <template #activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-icon style="margin-right: 10px">
              <v-icon>mdi-progress-star-four-points</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Import Modules</v-list-item-title>
          </v-list-item>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Import modules</v-list-item-title>
                <v-list-item-subtitle
                  >Based upon layout given in
                  <span class="font-weight-bold"
                    >Project Intake</span
                  ></v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <ant-input label="Ignore module types" is-optional>
                <template #input-field>
                  <v-checkbox
                    v-model="ignoreModuleTypes"
                    class="mt-0"
                    hide-details
                    :label="
                      ignoreModuleTypes
                        ? 'Module Types Ignored'
                        : 'Module Types used'
                    "
                  ></v-checkbox>
                </template>
              </ant-input>
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              small
              :disabled="importLoading"
              @click="importMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              small
              :disabled="importLoading"
              :loading="importLoading"
              @click="importFromLayout"
            >
              Import
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-list-item @click.stop="$refs['csv-import'].$refs.input.click()">
        <v-list-item-icon style="margin-right: 10px">
          <v-icon dense> mdi-file-import </v-icon>
        </v-list-item-icon>
        <v-list-item-title> Import CSV </v-list-item-title>
        <v-file-input
          ref="csv-import"
          v-model="csvImportFile"
          accept=".csv"
          style="display: none"
          @change="importCsvData()"
        />
      </v-list-item>
    </template>
    <template #table-actions>
      <v-chip v-if="deleteMode" color="warning" class="mr-2"
        ><v-icon>mdi-exclamation-thick</v-icon>DELETE MODE ACTIVE</v-chip
      >
      <v-text-field
        v-model="newBuildNr"
        placeholder="New build nr"
        dense
        single-line
        hide-details
        class="normal-text-field"
        filled
        clearable
        :append-outer-icon="newBuildNr?.length >= 2 ? 'mdi-plus' : ''"
        @keydown.enter="createRecord"
        @click:append-outer="createRecord"
        @keydown.esc="newBuildNr = null"
      />
      <delete-dialog
        :title="`Are you sure you want to delete module ${moduleToDelete?.build_nr}`"
        :dialog="moduleToDelete !== null"
        @closeDialog="moduleToDelete = null"
        @deleteAction="deleteModule"
      />
    </template>
    <template
      v-for="header in manualImportModuleHeaders.filter((x) => x.input)"
      #[`item.${header.value}`]="{ value, rowId, item }"
    >
      <td :key="header.value" class="ant-border-left">
        <v-text-field
          v-if="selectedCell === `${item.id}${ANT_DELIMITER()}${header.value}`"
          v-model="currentCellValue"
          dense
          single-line
          hide-details
          filled
          :type="header.type"
          autofocus
          @change="updateRecord(item.id, header.value, $event)"
          @keydown.enter="updateAndRowDown"
          @keydown.esc="resetCell(item.id, header.value)"
        />
        <div
          v-else
          class="full-height full-width d-flex align-center justify-center"
          @click="selectCell(item.id, header.value, value)"
        >
          <v-tooltip bottom color="warning">
            <template #activator="{ on, attrs }">
              <v-icon
                v-if="header.required && !value"
                v-bind="attrs"
                color="warning"
                v-on="on"
                >mdi-alert</v-icon
              >
            </template>
            <span>Required</span>
          </v-tooltip>
          {{ value }}
        </div>
      </td>
    </template>
    <template #item.phase="{ value, rowId, item }">
      <td class="ant-border-left">
        <v-select
          v-if="selectedCell === `${item.id}${ANT_DELIMITER()}phase`"
          :items="placementPhases"
          :value="value"
          item-text="title"
          item-value="id"
          class="normal-text-field"
          dense
          single-line
          hide-details
          filled
          clearable
          @change="updateRecord(rowId, 'phase', $event)"
        />
        <div
          v-else
          class="full-height full-width d-flex align-center justify-center"
          @click="selectCell(item.id, 'phase', value)"
        >
          {{
            placementPhases.find((phase) => phase.id === value)?.title ?? null
          }}
        </div>
      </td>
    </template>
    <template #item.2d_drawing="{ value, rowId, item }">
      <td class="ant-border-left">
        <v-file-input
          v-if="selectedCell === `${item.id}${ANT_DELIMITER()}2d_drawing`"
          :value="value"
          class="normal-text-field"
          dense
          single-line
          hide-details
          filled
          clearable
          accept="application/pdf"
          @change="uploadFile(rowId, '2d_drawing', $event)"
        />
        <div
          v-else
          class="full-height full-width d-flex align-center justify-center"
          @click="selectCell(item.id, '2d_drawing', value)"
        >
          <span v-if="value"> {{ value?.name }}.{{ value?.extension }} </span>
          <v-icon v-if="value" class="ml-2" @click.stop="previewDocument(value)"
            >mdi-eye</v-icon
          >
        </div>
      </td>
    </template>
  </dynamic-data-table>
</template>

<style scoped lang="scss">
td {
  padding: 0 !important;
}
::v-deep .v-text-field__details {
  height: 0;
  overflow: hidden;
  min-height: 0;
}

::v-deep .x-small-text-field {
  max-width: 100px !important;
}

::v-deep .small-text-field {
  max-width: 150px !important;
}

::v-deep .normal-text-field {
  max-width: 200px !important;
}
</style>
