<script>
import { defineComponent } from 'vue';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { DHME_MANUAL_IMPORT } from '@/modules/modules';
import { mapGetters } from 'vuex';
import { LaravelEchoClient } from '@/services/laravel-echo';

export default defineComponent({
  name: 'DHMEManualImport',
  components: { ModuleNavigationBar },
  data: () => {
    return {
      moduleTab: null,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      authenticatedUser: 'authenticatedUser',
      isLoading: 'dhmeManualImportStore/isLoading',
    }),
  },
  watch: {
    moduleTab(value) {
      switch (value) {
        case 0: {
          this.$router.push({ name: `${DHME_MANUAL_IMPORT}-modules` });
          break;
        }
        case 1: {
          if (this.$route.name !== `${DHME_MANUAL_IMPORT}-module-contents`)
            this.$router.push({
              name: `${DHME_MANUAL_IMPORT}-module-contents`,
            });
          break;
        }
        case 2: {
          if (this.$route.name !== `${DHME_MANUAL_IMPORT}-element-contents`)
            this.$router.push({
              name: `${DHME_MANUAL_IMPORT}-element-contents`,
            });
          break;
        }
        case 3: {
          if (this.$route.name !== `${DHME_MANUAL_IMPORT}-objects`)
            this.$router.push({ name: `${DHME_MANUAL_IMPORT}-objects` });
          break;
        }
        case 4: {
          if (this.$route.name !== `${DHME_MANUAL_IMPORT}-drawings`)
            this.$router.push({ name: `${DHME_MANUAL_IMPORT}-drawings` });
          break;
        }
      }
    },
  },
  async mounted() {
    if (this.$route.name === DHME_MANUAL_IMPORT) {
      this.moduleTab = 0;
    } else if (this.$route.name === `${DHME_MANUAL_IMPORT}-modules`) {
      this.moduleTab = 0;
    } else if (this.$route.name === `${DHME_MANUAL_IMPORT}-module-contents`) {
      this.moduleTab = 1;
    } else if (this.$route.name === `${DHME_MANUAL_IMPORT}-element-contents`) {
      this.moduleTab = 2;
    } else if (this.$route.name === `${DHME_MANUAL_IMPORT}-objects`) {
      this.moduleTab = 3;
    } else if (this.$route.name === `${DHME_MANUAL_IMPORT}-drawings`) {
      this.moduleTab = 4;
    }
  },
});
</script>

<template>
  <div class="d-flex flex-column overflow-y-auto">
    <module-navigation-bar title="Manual import"
      ><template #module-nav-actions>
        <v-tabs
          v-model="moduleTab"
          height="46"
          style="width: auto"
          background-color="transparent"
        >
          <v-tab> Modules </v-tab>
          <v-tab> Module Contents </v-tab>
          <v-tab> Element Contents </v-tab>
          <v-tab> Objects </v-tab>
          <v-tab> Drawings </v-tab>
        </v-tabs>
      </template></module-navigation-bar
    >
    <div class="flex-grow-1 d-flex flex-column overflow-y-auto">
      <v-progress-linear
        v-show="isLoading"
        indeterminate
        color="primary"
        height="5px"
      />
      <Transition name="simple-fade" mode="out-in">
        <router-view />
      </Transition>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
